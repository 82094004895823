import React from "react";
import { shape, string } from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Text, Container } from "../styles/styles";

const about = ({ data }) => {
  const { description: markdown } = data.contentfulLayoutAboutMe;
    return (
      <Layout>
        <Container>
          <Text className="mt-5">{markdown.description}</Text>

        </Container>
      </Layout>
    );
};
about.defaultProps = {
  data: {
    contentfulLayoutAboutMe: {
      description: {
        description: ""
      }
    },
  },
};

about.propTypes = {
  data: shape({
    contentfulLayoutAboutMe: shape({
      description: shape({
        description: string
      })
    }),
  }),
};

export default about

export const aboutQuery = graphql`
  query aboutQuery {
    contentfulLayoutAboutMe(slug: {eq: "about"}) {
      description {
        description
      }
      title
    }
  }
`;